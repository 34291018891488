import Grid from '@mui/material/Grid';
import { useAsideStyles } from 'layouts/auth/partials/aside/aside.styles';
import { FunctionComponent } from 'react';

export const Aside: FunctionComponent = () => {
  const classes = useAsideStyles();

  return (
    <Grid container item md={4} lg={7} component="aside" direction="row" className={classes.root} spacing={0}>
      {/* we are keeping this container for the background image */}
    </Grid>
  );
};
