import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useCookieConsentModalStyles = makeStyles((theme: Theme) => ({
  consentBox: {
    [theme.breakpoints.between('xs', 'lg')]: {
      maxWidth: '448px',
      margin: 0,
      marginBottom: '24px',
      height: 'fit-content'
    },
    maxWidth: 330,
    height: 283,
    fontSize: '14px',
    lineHeight: '22px',
    color: 'white',
    backgroundColor: '#247787e0',
    padding: '24px',
    textAlign: 'left',
    margin: '24px',
    '& h3': {
      marginTop: 0,
      marginBottom: '6px'
    },
  },
  p: {
    marginTop: 0,
    marginBottom: '18px',
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '160%',
    letterSpacing: '0.17px',
  },
  buttonDiv: {
    display: 'flex',
    gap: "18px"
  },
  button: {
    flex: 1,
    height: 'fit-content',
    lineHeight: '0px',
    alignItems: 'center',
    '& .MuiButton-startIcon': {
      alignSelf: 'center'
    }
  },
  declineBtn: {
    color: 'white',
    height: 'fit-content !important',
    lineHeight: '0px',
    alignItems: 'center',
    flex: 1,
    '& .MuiButton-startIcon': {
      alignSelf: 'center'
    }
  },
}));
