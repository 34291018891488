/* eslint-disable @roq/imports-should-follow-conventions */
import Box from '@mui/material/Box';
import Grid, { GridProps } from '@mui/material/Grid';
import { useAuthLayoutStyles } from 'layouts/auth/auth.styles';
import { Content, ContentInterface, Header } from 'layouts/auth/partials';
import Head from 'next/head';
import { FunctionComponent, ReactNode } from 'react';
import { CookieConsentModal } from 'views/login/partials/cookie-consent-modal/cookie-consent-modal.partial';

export interface AuthLayoutInterface {
  title: string;
  back?: ReactNode;
  footer?: ReactNode;
  noAside?: boolean;
  aside?: ReactNode;
  children?: ReactNode;
  AsideProps?: GridProps;
  ContentProps?: ContentInterface;
  HeaderProps?: GridProps;
  visible?: boolean;
  onAccept?: () => void;
  onDecline?: () => void;
}

export const AuthLayout: FunctionComponent<AuthLayoutInterface> = (props) => {
  const {
    title,
    children,
    noAside: isWithHeadings,
    ContentProps,
    HeaderProps,
    back,
    visible,
    onAccept,
    onDecline,
  } = props;
  const classes = useAuthLayoutStyles();

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <Box className={classes.root}>
        <Grid container className={classes.grid} justifyContent="center" alignItems={'center'}>
          <Grid item>
            <Box>
              <Header {...HeaderProps} isWithHeadings={isWithHeadings} />
              <Box className={classes.consentBox}>
                <CookieConsentModal visible={visible} onAccept={onAccept} onDecline={onDecline} />
              </Box>
              <Box className={classes.formSide}>
                <Content {...ContentProps}>{children}</Content>
              </Box>
            </Box>
            {isWithHeadings && <div className={classes.backToSignIn}>{back}</div>}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
