import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import { FunctionComponent } from 'react';
import { useCookieConsentModalStyles } from 'views/login/partials/cookie-consent-modal/cookie-consent-modal.styles';

interface CookieConsentModalInterface {
  visible: boolean;
  onAccept: () => void;
  onDecline?: () => void;
}

export const CookieConsentModal: FunctionComponent<CookieConsentModalInterface> = (props) => {
  const { visible, onAccept, onDecline } = props;
  const classes = useCookieConsentModalStyles();

  return (
    <>
      {visible && (
        <Box className={classes.consentBox}>
          <h3>Cookies</h3>
          <p className={classes.p}>
            We use cookies to store your login information to keep this site performant and secure. We are not using any
            analytics, tracking, or advertisement tools and don’t share your information with 3rd parties.
          </p>
          <div className={classes.buttonDiv}>
            <Button
              onClick={onDecline}
              className={classes.declineBtn}
              startIcon={<CancelOutlinedIcon />}
              variant="outlined"
              size="large"
            >
              Decline
            </Button>
            <Button
              onClick={onAccept}
              className={classes.button}
              color={'secondary'}
              startIcon={<CheckCircleOutlineOutlinedIcon />}
              variant="contained"
              size="large"
            >
              Accept
            </Button>
          </div>
        </Box>
      )}
    </>
  );
};
