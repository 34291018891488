import Grid, { GridProps } from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import { useHeaderStyles } from 'layouts/auth/partials/header/header.styles';
import Logo from 'modules/common/icons/helm-logo.svg';
import { FunctionComponent } from 'react';

export interface HeaderInterface extends GridProps {
  isWithHeadings: boolean;
}

export const Header: FunctionComponent<HeaderInterface> = ({ isWithHeadings }) => {
  const classes = useHeaderStyles();

  return (
    <Grid container component="header" className={classes.root} direction={'column'} justifyContent={'center'}>
      <Grid item className={classes.logoWrapper}>
        <SvgIcon component={Logo} sx={{ width: 64, height: 40 }} viewBox="0 0 64 40" className={classes.logo} />
      </Grid>
      {!isWithHeadings && (
        <>
          <Grid item>
            <h1 className={classes.heading}>HELM BRIDGE</h1>
          </Grid>
          <Grid item>
            <h3 className={classes.slogan}>The Power of Partnership</h3>
          </Grid>
        </>
      )}
    </Grid>
  );
};
