import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useAuthLayoutStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    position: 'relative',
    [theme.breakpoints.between('xs', 'xl')]: {
      overflow: 'auto'
    }
  },
  grid: {
    height: 'auto',
    minHeight: '100%',
    position: 'relative',
    display: 'flex',
    background: 'url(/static/images/helm-background.png) no-repeat center center fixed',
    [theme.breakpoints.only('xs')]: {
      paddingLeft: '24px',
      paddingRight: '24px'
    },
    backgroundSize: 'cover',
  },
  formSide: {
    color: theme.palette.mode === 'light' ? theme.palette.grey[700] : theme.palette.grey[200],
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    maxWidth: '450px',
    [theme.breakpoints.between('md', 'xl')]: {
      minWidth: '400px',
      maxWidth: '448px',
    },
    [theme.breakpoints.between('xl', 'xxl')]: {
      maxWidth: '448px',
    },
  },
  consentBox: {
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      left: 0,
      bottom: 0,
    },
  },
  noAsideMain: {
    color: theme.palette.mode === 'light' ? theme.palette.grey[700] : theme.palette.grey[200],
    backgroundColor: theme.palette.background.paper,
  },
  backToSignIn: {
    paddingTop: '15px',
    paddingBottom: '15px',
    textAlign: 'center',
  },
}));
